import "./bootstrap";
import $ from 'jquery';
window.$ = window.jQuery = $;

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal } from 'bootstrap';
window.Modal = Modal;
import '@popperjs/core';
import PerfectScrollbar from 'perfect-scrollbar';

import select2 from 'select2';
select2($);

import 'laravel-datatables-vite';
import swal from 'sweetalert2';
window.Swal = swal;

